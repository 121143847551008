export function PageNotFound() {
	return (
		<div className="row">
			<div className="col-xs-12">
				The page you are looking for could not be found.
			</div>
		</div>
	);
}

export default PageNotFound;

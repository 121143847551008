import "../css/main.css";
import NavBar from "./components/NavBar";

function App() {
	return (
		<div className="App text-light">
			<NavBar />
		</div>
	);
}

export default App;

export function ProgressBar() {
	return (
		<div className="progress mt-4 shadow-sm" role="progressbar">
			<div className="progress-bar bg-ww-orange" style={{ width: "10%" }}>
				10%
			</div>
		</div>
	);
}

export default ProgressBar;
